import React, { Suspense, useState, useContext, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Backdrop, CircularProgress, Container, Typography, useMediaQuery, useTheme } from "@mui/material";

import NavigationPaths from "../../routes/navigationPath";
import PublicRoute from "../../routes/PublicRoute";
import { ColorModeContext } from "../../theme/theme";

const Home = React.lazy(() => import("../../pages/publicPages/home/index"));
// const HomeMobileView = React.lazy(() => import("../../pages/publicPages/home/homeMobileView/index"));
const ContactUs = React.lazy(() => import("../../pages/publicPages/contactUs/index"))
const About = React.lazy(() => import("../../pages/publicPages/about/index"));
const Jobs = React.lazy(() => import("../../pages/publicPages/jobs/index"));
const Blogs = React.lazy(() => import("../../pages/dynamicPages/Blogs"))
const BlogsDetails = React.lazy(() => import("../../pages/dynamicPages/BlogsDetails"))
const FAQ = React.lazy(() => import("../../pages/publicPages/faq/index"))
const Term = React.lazy(() => import("../../pages/publicPages/term/index"))
const PrivacyPolicy = React.lazy(() => import("../../pages/publicPages/privacyPolicy/index"))
const CommunityGuidelines = React.lazy(() => import("../../pages/publicPages/communityGuidelines/index"))

const DownloadApp = React.lazy(() => import("../../pages/publicPages/downloadApp/index"))

const PageNotFound = React.lazy(() => import("../../pages/pageNotFound/index"));

const Main = () => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const colorMode = useContext(ColorModeContext);
    const inMobileView = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathname]);

    function RenderHomePage() {
        if (inMobileView) {
            // return <HomeMobileView />;
            return <Home />;

        } else {
            return <Home />;
        }
    }

    if (inMobileView) {
        window.addEventListener('load', colorMode.toggleColorMode);
    } else {
        window.removeEventListener('unload', colorMode.toggleColorMode);
    }

    return (
        <Container component="main" disableGutters maxWidth="false" sx={{ minHeight: "100vh", bgcolor: "#FAFAFA" }}>
            <Suspense
                fallback={<Backdrop
                    open={open}
                    sx={{ color: "##000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    onClick={() => setOpen(false)}>
                    <CircularProgress />
                    <Typography variant="h3">
                        Loading...
                    </Typography>
                </Backdrop>}>
                <Routes>
                    <Route path={NavigationPaths.HOME} element={<PublicRoute><RenderHomePage /></PublicRoute>} />
                    <Route path={NavigationPaths.ABOUTUS} element={<PublicRoute><About /></PublicRoute>} />
                    <Route path={NavigationPaths.JOBS} element={<PublicRoute><Jobs /></PublicRoute>} />
                    <Route path={NavigationPaths.CONTACT} element={<PublicRoute><ContactUs /></PublicRoute>} />
                    <Route path={NavigationPaths.BLOGS} element={<PublicRoute><Blogs /></PublicRoute>} />
                    <Route path={NavigationPaths.BLOGDETAILS} element={<PublicRoute><BlogsDetails /></PublicRoute>} />
                    <Route path={NavigationPaths.FAQ} element={<PublicRoute><FAQ /></PublicRoute>} />
                    <Route path={NavigationPaths.TERM} element={<PublicRoute><Term /></PublicRoute>} />
                    <Route path={NavigationPaths.PRIVACYPOLICY} element={<PublicRoute><PrivacyPolicy /></PublicRoute>} />
                    <Route path={NavigationPaths.COMMUNITYGUIDELINES} element={<PublicRoute><CommunityGuidelines /></PublicRoute>} />
                    <Route path={NavigationPaths.DOWNLOAD_APP} element={<PublicRoute><DownloadApp /></PublicRoute>} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Suspense>
        </Container >
    );
};

export default Main;
