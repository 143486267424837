import React from "react"
import { Container, Box, Accordion, AccordionSummary, AccordionDetails, Button, Typography, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {

    const faqData = [
        {
            title: 'What is Pickzon all about?',
            description: `Pickzon was created to go beyond the typical dating app experience. We’re all about fostering real connections in a world of endless swipes and ghosted texts. Whether you’re looking for a date, a travel buddy, or just a friend to chat with, Pickzon is the place where genuine connections happen`
        },
        {
            title: 'How do I create a profile on Pickzon?',
            description: `Simply download the Pickzon app, sign up with your email or phone number, and complete your profile. We recommend getting verified with our gesture verification and Government ID verification options to help create a safe platform with genuine users.`
        },
        {
            title: 'Where can I download Pickzon?',
            description: `You can download the Pickzon app from the Google Play Store or the Apple App Store.`
        },
        {
            title: 'What does the Pickzon’s Boost feature do?',
            description: `With Pickzon’s Boost feature, your profile gets a front-row seat, making sure more people see you! It’s like jumping to the top of the guest list—everyone’s going to notice. `
        },
        {
            title: 'What’s the difference between Premium and Premium+ plans?',
            description: `
            <p><strong>Premium Plan Features:</strong></p>
            <ul>
            <li><strong>Unlimited Likes – </strong>Swipe through as many profiles as you like, with no limits.</li>
            <li><strong>Direct Messaging – </strong>Message anyone, even without a match. You can message directly via Comments & Notifications</li>
            <li><strong>Unlimited Extends – </strong>Match with as many people as you’d like.</li>
            <li><strong>See Who Liked You – </strong>Easily view who’s interested in you.</li>
            <li><strong>More Likes for You – </strong>Access an endless selection of recommended profiles.</li>
            <li><strong>5 Superlikes per Week – </strong>Catch attention with 5 standout superlikes weekly.</li>
            <li><strong>See Premium Profiles – </strong>Connect with exclusive Premium members.</li>
            <li><strong>Stand Out Every Day – </strong>Appear at the top of others’ feeds daily.</li>
            <li><strong>Clear Chat – </strong>Clear entire chats from your side or unsend single messages for both parties within 5 minutes</li>
            </ul>
            <p></p>
            <p><strong>Premium+ Plan:</strong></p>
            <p>The Premium+ plan includes all the Premium features plus three extras:</p>
            <ul>
            <li><strong>1 Boost per Week – </strong>Boost your profile to get more views for 30 minutes per boost.</li>
            <li><strong>Secret Mode – </strong>Stay hidden from your family, friends, boss, and ex. You’ll only be visible to the people you like.</li>
            <li><strong>Travel Mode – </strong>Change your location to match with people in other cities, no matter where you are!</li>
            </ul>
            `
        },
        {
            title: 'What benefits do Premium users get?',
            description: `
            <p>Premium users enjoy a range of exclusive features designed to enhance their Pickzon experience:</p>
            <ul>
            <li><strong>Unlimited Likes – </strong>Swipe as much as you want, with no limits.</li>
            <li><strong>Direct Messaging – </strong>Send messages to anyone, even without a match.</li>
            <li><strong>Unlimited Extends – </strong>Match with an unlimited number of people.</li>
            <li><strong>See Who Liked You – </strong>View users who have liked your profile.</li>
            <li><strong>More Likes for You – </strong>Access a larger pool of recommended profiles.</li>
            <li><strong>5 Superlikes per Week – </strong>Stand out with five superlikes each week.</li>
            <li><strong>See Premium Profiles – </strong>Connect with other exclusive Premium members.</li>
            <li><strong>Stand Out Every Day – </strong>Get priority placement at the top of others’ feeds.</li>
            </ul>
            <p></p>
            <p>These benefits give Premium users a higher chance of connecting with others, making matches more visible and meaningful.</p>            
            `
        },
        {
            title: 'Can I see who has liked me before I like them back?',
            description: `Yes! Pickzon’s ‘Liked You’ section shows all the profiles that have liked you. However, for non-Premium users and non-Premium+ users, these profiles appear with blurred images and no names—giving a hint without revealing details. To view the full profiles of those who liked you, you’ll need a Premium or Premium+ membership.`
        },
        {
            title: 'How do I match with someone on the Pickzon app?',
            description: `
            To match with someone on the Pickzon app, simply like their profile while swiping. If they like you back, it’s a match!
<p></p>
Additionally, Premium and Premium+ users have extra options—they can send likes through both the Explore section and the Liked You section of the app.
            `
        },
        {
            title: 'Can I extend matches or increase my daily swipe limit on Pickzon?',
            description: `
            Yes, you can extend matches and increase your daily swipe limit, but these features are available exclusively for Premium and Premium+ users. Both plans allow users to like unlimited profiles and have unlimited matches`

        },
        {
            title: 'How do I send virtual gifts like chocolates, roses, or rings on Pickzon?',
            description: `
            To send virtual gifts like chocolates, roses, or rings on Pickzon, first, purchase your chosen gift. 
            <p></p>
            Once you've bought the gift, you can send it to anyone by clicking the gift icon located at the top right of each profile picture while swiping. After clicking the icon, select the type of gift you'd like to send to that profile.
            
            `
        },
        {
            title: 'How can I delete my account? ',
            description: `
            To delete your account, tap the 'Profile' icon at the bottom right of the app. Next, tap the settings icon in the top-right corner. Scroll down and tap ‘Delete Account’ just below the 'Log Out' button.
            `
        },
        {
            title: 'How do I report an account on Pickzon? ',
            description: `
            If you’ve already matched with someone, go to the 'Chats' section, open your chat with them, and tap the three dots in the top-right corner. You’ll see options to 'Report' or 'Block' them. Tap 'Report'
 <p></p>
If you're reporting someone while swiping, scroll to the bottom of their profile and tap ‘Report [name]’
            `
        },
        {
            title: 'How do I block an account on Pickzon? ',
            description: `
            If you've matched with someone, open your chat with them, tap the three dots in the top-right corner, and choose ‘Block User.’
 <p></p>
To block someone while swiping, scroll to the bottom of their profile and tap ‘Block [name].’
            `
        },
    ]

    return (
        <>
            <Container maxWidth="xl" disableGutters={true} >
                <Box sx={{ bgcolor: '#F11A7B', textAlign: 'center', height: { xs: 'auto', md: '58vh' } }} >

                    <Typography variant="h3" sx={{ fontFamily: 'Raleway', color: 'white', fontWeight: 800, paddingTop: 11, paddingBottom: 1 }}>
                        FREQUENTLY ASKED QUESTIONS
                    </Typography>

                    <Typography sx={{ fontFamily: 'Poppins', color: 'white', paddingLeft: { xs: 5, md: 30 }, paddingRight: { xs: 5, md: 30 } }}>
                        Got a question about finding your perfect match? Explore our FAQs here. If you don’t find the answer you’re looking for, feel free to reach out via our {" "}
                        <Typography component={'a'} href={"contact-us"} sx={{ textDecoration: 'underline', color: 'white' }}>contact form</Typography>
                    </Typography>

                    <Box sx={{ paddingTop: 4 }}>
                        <TextField placeholder="Type your questions here" sx={{ width: 500, maxWidth: '100%', bgcolor: 'white', borderRadius: '9px' }} />
                        <Button variant="contained" sx={{ bgcolor: 'white', color: '#F11A7B', fontWeight: 600, top: 3, marginLeft: 2 }}>Search</Button>
                    </Box>

                </Box>
            </Container >

            <Container maxWidth="" disableGutters={true} >
                <Box sx={{ padding: 5 }}>
                    {faqData.map((item, i) => (
                        <Accordion defaultExpanded={i === 0 ? true : false} key={i}
                            sx={{ boxShadow: 'none', bgcolor: '#FAFAFA' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography variant="h5"> {item.title} </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                />
                                {/* <Typography>{item.description} </Typography> */}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
        </>
    )
};

export default FAQ;