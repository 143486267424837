import React, { Fragment } from "react"
import { Box, Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
    const privacyData = [
        { title: "1. Information We Collect", description: "We collect two types of information from you:" },
        {
            title: "a) Personal Information", description: `
            <ul>
                <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Registration Data: </span> When you sign up for Pickzon, we collect your name, email address, phone number, gender, and date of birth. We may also collect additional profile information, such as your interests and preferences, to provide a better match experience.</li>
                <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Optional Gesture Verification and Government ID Verification: </span>You may optionally submit a picture for gesture verification and/or provide your government ID for enhanced profile authenticity.</li>
                <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Location Data: </span> We may collect your precise or approximate location data, primarily to show you potential matches nearby. </li>
                <li> <span style="color: black; font-weight: 600"> Payment Information: </span> When you choose to purchase premium features, we securely collect your payment details, such as credit card information or other financial data. Rest assured, we use industry-standard encryption and security protocols to protect your payment information and ensure your data remains safe and confidential. Your privacy is our priority, and we only collect the necessary information to process your transaction smoothly and securely. </li>
        </ul>
            `
        },
        {
            title: "b) Non-Personal Information", description: `
            <ul>
                <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Usage Data:</span> We collect data related to your activity within the app, such as the profiles you view, your likes, and your interactions (messages, calls, etc.). </li>
                <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Device Information:</span> We collect information about the device and operating system you use to access Pickzon, including device IDs, IP addresses, and mobile network data. </li>
                <li> <span style="color: black; font-weight: 600"> Cookies and Tracking Technologies:</span> We use cookies and similar tracking technologies to improve your experience, remember your preferences, and monitor site usage.</li>
            </ul>
            ` },
        {
            title: "2. How We Use Your Information",
            description: `
                We use the information we collect to:
                <ul>
                    <li style="padding-bottom: 10px"> Facilitate matches and connections based on your preferences and location. </li> 
                    <li style="padding-bottom: 10px"> Improve and personalize the Pickzon experience, including suggesting profiles based on your interactions. </li> 
                    <li style="padding-bottom: 10px"> Verify your identity through gesture selfies to ensure the safety and authenticity of our users. </li> 
                    <li style="padding-bottom: 10px"> Process payments for premium subscriptions. </li> 
                    <li style="padding-bottom: 10px"> Send you relevant promotional offers, updates, and communications. </li> 
                    <li> Analyse usage patterns to improve our services and detect potential fraud or illegal activities. </li> 
                </ul>
            `
        },
        {
            title: "3. Sharing Your Information",
            description: `
            We do not sell your personal information to third parties. However, we may share your data in the following cases:
            <ul>
               <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> With other users: </span> Your profile information (name, photos, age, interests, etc.) is visible to other Pickzon users. </li>
               <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Service Providers: </span> We may share data with third-party service providers who assist in operating Pickzon, such as payment processors, hosting services, and customer support. </li>
               <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Legal Requirements: </span> If required by law or in response to legal proceedings, we may disclose your personal information. </li>
               <li> <span style="color: black; font-weight: 600"> Business Transfers: </span> If Pickzon is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction. </li>
            </ul>
            `
        },
        {
            title: "4. Data Security",
            description: `
               We implement industry-standard measures to protect your personal information, including encryption and secure storage solutions. However, no system is entirely secure, and we cannot guarantee absolute security. We encourage users to be vigilant in safeguarding their own information.               
            ` },
        {
            title: "5. Your Rights",
            description: `
            As a Pickzon user, you have the following rights:
            <ul>
               <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Access & Rectification: </span> You can access, update, or correct your personal information via the app settings. </li>
               <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Withdrawal of Consent: </span> You can withdraw consent for the processing of your data where applicable. This may limit your access to certain features. </li>
               <li style="padding-bottom: 10px"> <span style="color: black; font-weight: 600"> Data Portability: </span> You can request a copy of the personal data we hold about you in a structured, machine-readable format. </li>
               <li> <span style="color: black; font-weight: 600"> Opt-Out of Marketing: </span> You can opt out of receiving promotional communications at any time by adjusting your notification settings or following the unsubscribe link in our emails. </li>
            </ul>

        ` },
        { title: "6. International Data Transfers", description: "Pickzon operates globally, and your information may be transferred and processed in countries outside your home jurisdiction. These countries may have different data protection laws, but we ensure that adequate safeguards are in place to protect your personal information." },
        { title: "7. Children’s Privacy", description: "Pickzon is not intended for use by anyone under the age of 18. We do not knowingly collect personal information from minors. If we become aware that a user is under 18, we will take steps to remove their account and data." },
        {
            title: "8. Changes to the Privacy Policy",
            description: `We may update this Privacy Policy from time to time to reflect changes in our practices or for legal, operational, or regulatory reasons. You will be notified of any significant changes via email or in-app notifications.`
        },
        {
            title: "9. App Permissions",
            description: `
            Pickzon requests the following permissions to provide a seamless and enhanced user experience. Each permission is used strictly for the purposes outlined below, ensuring transparency and respect for user privacy.
        ` },
        {
            title: "A.  Camera Permission",
            description: `
            The app requests access to your camera for the following purposes:
            <ul>
            <li><strong>Profile Creation: </strong>To allow users to take a picture during sign-up for their profile. Users may alternatively upload a photo from their gallery.</li>
            <li><strong>Edit Profile: </strong>To take and upload a new profile picture after account creation via the "Edit Profile" section.</li>
            <li><strong>Gesture Verification: </strong>For optional identity verification, where users replicate a shown pose to confirm the authenticity of their profile.</li>
            <li><strong>Government ID Verification: <strong>To click and upload a photo of your government-issued ID for verification.</li>
            </ul>
        ` },
        {
            title: "B.  Photo Library/Storage Permission",
            description: `
            The app requests access to your photo library or device storage for the following purposes:
            <ul>
            <li><strong>Profile Creation: </strong>To upload a profile picture during sign-up from your gallery or local storage.</li>
            <li><strong>Edit Profile: </strong>To select and upload a new profile picture after signing up.</li>
            <li><strong>Government ID Verification: <strong>To upload a saved image of your government-issued ID for optional identity verification.</li>
            </ul>
        ` },
        {
            title: "C.  Location Permission",
            description: `
            The app requests access to your location to provide features and services such as:
            <ul>
            <li><strong>During Sign-Up: </strong>Location permission is requested during the sign-up process to enable the app to show profiles of people near you.</li>
            <li><strong>While Using the App: </strong>Your location will be used in the background to display profiles based on your proximity and the preferences you set in the Filter section of the "Explore" page. Until you set a filter, the app will use your location to show you all the profiles available in the "Explore" section.</li>
            <li><strong>Travel Mode: </strong>Location permissions are required when you activate Travel Mode, allowing you to explore matches in a different location. This feature can be accessed through the app's settings under "Location."</li>
            <li><strong>Control Your Access: </strong>You can revoke location access anytime via your device settings.</li>
            </ul>
        ` },
        {
            title: "D.  Voice Recording Permission",
            description: `
            The app requests access to your microphone for the following purpose:
            <ul>
            <li><strong>Voice Messaging: </strong>Required if you choose to send voice messages while chatting with other users.</li>
            </ul>
        ` },
        {
            title: "E.  Messaging Permission (Planned Future Feature)",
            description: `
            The app may request access to SMS messages solely for the purpose of:
            <ul>
            <li><strong>OTP Auto-Fetching: </strong>Required to upload pictures or other content to your profile.</li>
            </ul>
            Pickzon ensures that these permissions are used strictly for the stated purposes and does not misuse or sell user data. You may control these permissions through your device’s settings at any time. Disabling certain permissions may limit the functionality of specific features but will not affect your general access to the app.
        ` },
        {
            title: "10. Account Deletion",
            description: `
            You may delete your account at any time through the settings in the Pickzon app. Once your account is deleted:
            <ul>
            <li>All your data, including profile information, messages, and interactions, will be permanently deleted and cannot be recovered.</li>
            <li>Deleted accounts cannot be restored.</li>
            </ul>
            If you choose to delete your account, Pickzon will initiate the deletion process immediately, and your data will be permanently removed from our systems, subject to legal or regulatory requirements.
        ` },
        {
            title: "11.  Refund Policy",
            description: `
            Refund requests may be submitted to help@pickzon.com.
            <ul>
            <li>Refunds for subscriptions purchased through third-party platforms should be directed to those platforms following their respective policies.</li>
            <li>Refunds are granted at Pickzon’s sole discretion.</li>
            </ul>
        ` },
        {
            title: "12.  Third-Party Services",
            description: `
            For a better experience, while using our application, we may require you to provide us with certain personally identifiable information, including but not limited to. We do not sell or misuse any data collected from the user. The information that we request will be retained by us and used as described on this privacy policy page.

 

            <p>The application does use third-party services that may collect information used to identify you. Below are links to the privacy policies of the third-party service providers used by our application:</p>
            <ul>
            <li><a href="https://policies.google.com/privacy" target="_blank">Google Play Services</a></li>
            <li><a href="https://firebase.google.com/policies/analytics" target="_blank">Google Analytics for Firebase</a></li>
            <li><a href="https://firebase.google.com/support/privacy/" target="_blank">Firebase Crashlytics</a></li>
            </ul>
        ` },
        {
            title: "13. Contact Us",
            description: `If you have any questions or concerns about this Privacy Policy or how your information is handled, please contact us at:
           
            `
        }
    ]
    return (
        <Container maxWidth="" disableGutters={true} >
            <Box style={{ padding: '20px' }}>
                <Typography align="center" sx={{ fontSize: { xs: 30, md: 45 }, fontWeight: 800, fontFamily: 'Poppins' }} >
                    Privacy Policy
                </Typography>
                <Typography sx={{ paddingLeft: { md: 25, xs: 1 }, paddingRight: { md: 25, xs: 1 }, paddingBottom: 2, fontFamily: 'Poppins' }} >
                    At Pickzon, we are committed to protecting your privacy and ensuring a safe and secure user
                    experience. This Privacy Policy outlines the types of information we collect, how we use, share,
                    and safeguard it, and your rights concerning your personal information. By using Pickzon, you
                    consent to the practices described below.
                </Typography>
                <Box sx={{ paddingLeft: { xs: 1, md: 15 }, paddingRight: { xs: 1, md: 15 }, paddingBottom: 3 }} >
                    {privacyData.map((item, index) => (
                        <Fragment key={index}>
                            <Typography variant="h6" key={index} sx={{ fontWeight: 600, paddingTop: 2 }}>{item.title}</Typography>
                            <Typography sx={{ color: '#666666' }}
                                dangerouslySetInnerHTML={{ __html: item.description }}
                            />
                        </Fragment>
                    ))}
                    <Typography variant="h5" sx={{ paddingTop: 3 }}>Email: <a href="mailto:help@pickzon.com">help@pickzon.com</a></Typography>
                </Box>
            </Box>
        </Container>
    )
}

export default PrivacyPolicy;