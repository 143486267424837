import React from "react";
import {
    // useLocation,
    useNavigate
} from "react-router-dom";
import {
    Box, Typography, Grid2 as Grid,
    // useTheme, useMediaQuery,
    Toolbar
} from "@mui/material";

import NavigationPaths from "../../routes/navigationPath";
import { Assets } from "../../assets/Assets";

const Footer = () => {
    // const location = useLocation();
    const navigate = useNavigate();
    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints?.down("sm"));
    // const path = location.pathname.split("/")[1].split("-")[0]

    const footerFirstLink = [
        { url: 'https://www.pickzon.com/about', label: 'About' },
        { url: 'https://www.pickzon.com/contact-us', label: 'Contact' },
        { url: 'https://www.pickzon.com/jobs', label: 'Jobs' },
        { url: 'https://www.pickzon.com/faq', label: 'FAQs' }
    ];
    const footerSecondLink = [
        { url: 'https://www.pickzon.com/terms', label: 'Terms & Conditions' },
        { url: 'https://www.pickzon.com/privacy-policy', label: 'Privacy Policy' },
        { url: 'https://www.pickzon.com/community-guidelines', label: 'Community Guidelines' }
    ];

    const socialLogo = [
        { url: 'https://www.facebook.com/pickzon.inc', src: Assets.facebook.img, alt: Assets.facebook.alt },
        { url: 'https://www.instagram.com/pickzon_', src: Assets.instagram.img, alt: Assets.instagram.alt },
        { url: 'https://www.linkedin.com/company/pickzon', src: Assets.linkedIn.img, alt: Assets.linkedIn.alt }
    ]

    const onTabLogo = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return navigate(NavigationPaths.HOME);
    };

    const webFooter = (
        <Box component="footer" sx={styles}>
            <Grid container spacing={{ xs: 2, md: 2 }} sx={{ paddingTop: 4 }}>
                <Grid size={{ xs: 0, md: 3 }} ></Grid>

                <Grid size={{ xs: 12, md: 6 }} sx={{ textAlign: 'right' }}>
                    <Toolbar component={'nav'} sx={{ padding: "0px 85px 0px !important", justifyContent: 'center' }}>
                        <Box onClick={onTabLogo} sx={{ cursor: "pointer" }}>
                            <img src={Assets.logo.img} alt={Assets.logo.alt} />
                            <Typography fontSize={"1rem"} align="right" color={"primary.100"}>
                                Connecting Hearts</Typography>
                        </Box>
                    </Toolbar>

                    <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                        {footerFirstLink.map((item, i) => (
                            <Typography key={i} component={"a"} href={item.url} sx={styles.linkText}>{item.label}</Typography>))}
                    </Box>

                    <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                        {footerSecondLink.map((item, i) => (
                            <Typography key={i} component={"a"} href={item.url} sx={styles.linkText}>{item.label}</Typography>
                        ))}
                    </Box>

                    <Box sx={{ marginTop: '25px', textAlign: 'center' }}>
                        {socialLogo.map((ele, i) => (
                            <Typography key={i} component={"a"} href={ele.url} sx={styles.linkText}>
                                <img src={ele.src} alt={ele.alt} key={i} />
                            </Typography>
                        ))}
                    </Box>
                </Grid>

                <Grid size={{ xs: 12, md: 3 }} sx={{ textAlign: 'center' }} >
                    <img src={Assets.QR.img} alt={Assets.QR.alt} />
                    <Typography>Scan & Start Connecting!</Typography>
                </Grid>
            </Grid>

            <Box sx={styles.boxCopyRight}>
                <Typography>© 2024 PICKZON INC. All Rights Reserved.</Typography>
            </Box>


        </Box >
    )

    // const mobileFooter = (
    //     <Box component="footer" sx={{
    //         position: "sticky",
    //         bottom: 0,
    //         zIndex: 2
    //     }}>
    //         <Typography>Mobile Footer</Typography>
    //     </Box >
    // )

    return (
        <>
            {/* {path === "app" ? null : (path && isMobile) ? mobileFooter : webFooter} */}
            {webFooter}
        </>
    );
};

export default Footer;

const styles = {
    boxCopyRight: {
        borderTop: '2px solid #F11A7B',
        marginLeft: { xs: '2vh', md: '12vh' },
        marginRight: { xs: '2vh', md: '12vh' },
        marginTop: '5vh',
        padding: { xs: '2vh', md: '5vh' },
        textAlign: 'center',
        fontFamily: 'Roboto'
    },
    linkText: {
        textDecoration: "none",
        color: '#150E06',
        "&:hover": { textDecoration: "underline" },
        paddingRight: { xs: '15px', md: '50px' },
        justifyContent: 'center',
        fontSize: { xs: 12, md: '1rem' }
    },
}