import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "../header";
import Footer from "../footer";
import Main from "../main/index";

import NavigationPaths from "../../routes/navigationPath";
import Term from "../../pages/publicPages/term";
import PrivacyPolicy from "../../pages/publicPages/privacyPolicy";
import FAQ from "../../pages/publicPages/faq";

const Layout = () => {
  const pathName = window.location.pathname

  return (
    <>
      <BrowserRouter>
        {pathName === NavigationPaths.APPTERM || pathName === NavigationPaths.APPFAQ || pathName === NavigationPaths.APPPRIVACYPOLICY ?
          <Routes>
            <Route path={NavigationPaths.APPTERM} element={<Term />} />
            <Route path={NavigationPaths.APPFAQ} element={<FAQ />} />
            <Route path={NavigationPaths.APPPRIVACYPOLICY} element={<PrivacyPolicy />} />
          </Routes>
          : <>
            <Header />
            <Main />
            <Footer />
          </>}
      </BrowserRouter>
    </>
  )
}

export default Layout