import React, { Fragment } from "react"
import { Box, Container, Typography } from '@mui/material';

const Term = () => {
    const termData = [
        { title: "1. Acceptance of Terms", description: "By downloading, accessing, or using Pickzon (the “App”), you agree to be bound by these Terms and Conditions, our Privacy Policy, and all applicable laws and regulations. If you do not agree, please do not use the App." },
        {
            title: "2. Eligibility", description: `
            <ul>
            
            <li style="padding-bottom: 10px"> You must be at least 18 years old to use Pickzon. </li>            
            <li style="padding-bottom: 10px"> 
                By creating an account, you represent and warrant that you have the right, authority,<br/>
                and capacity to enter into these Terms and comply with them.
            </li>

            <li style="padding-bottom: 10px">
                You must not have been convicted of any criminal offense related to violence, sexual misconduct, or fraud.
            </li>
            
             <li style="padding-bottom: 10px">
                You must comply with all applicable laws, including the Information Technology Act,2000, and the rules framed thereunder.
            </li>
        </ul>
            `
        },
        {
            title: "3. Account Registration", description: `
            <ul>
                <li style="padding-bottom: 10px">
                    To access the App, you must create an account. You must provide accurate, current, and complete information, including a mandatory selfie for verification purposes.
                </li>
                <li>
                    You are responsible for maintaining the confidentiality of your account credentials and must notify us immediately if you suspect unauthorized access.
                </li>
                <li>Users are encouraged to ensure their account information, such as phone numbers and email addresses, is kept up to date.</li>
            </ul>
            ` },
        {
            title: "4. Collection of Information",
            description: `<strong>Registration Information</strong>
            When you create an account, we may collect the following information:
            <ul><li>Name</li><li>Username</li><li>Email address</li><li>Phone number</li><li>Gender identity</li><li>Date of birth</li><li>Sexual preference</li><li>Photographs</li><li>Location</li></ul>
            You can review and update this information at any time through the App, except for certain details like your date of birth and current location (which may update automatically based on your device settings).
            <p><strong>Profile Information</strong></p>
           <p> Please carefully consider the information you share in your profile. Sensitive details, such as credit card information, full names, or national IDs, should not be shared publicly in your profile.</p>

Currently, the App does not allow users to include URLs in their profiles. However, this feature may be added in the future, in which case the App will notify users of any related policy changes.
<p><strong>Purchases Information</strong></p>
If you purchase premium services, your payment information will be processed securely and retained only for purposes like fraud prevention and compliance with tax laws.
<p><strong>Geolocation Information</strong></p>
If you grant the App access to your location, we may collect and use your geolocation data to enhance your experience, such as showing profiles of nearby users. You can revoke location access through your device settings.            
`
        },
        {
            title: "5.  Free Trials and Credits",
            description: `Pickzon may offer free trials or credits for Premium Services at its discretion. Such trials or credits will be available for a limited time, and the terms associated with them will be provided during the offer. Free trials are limited to one per user and cannot be combined with other offers unless stated otherwise.`
        },
        {
            title: "6. Subscriptions and Auto-Renewal",
            description: `
                Pickzon offers automatically renewing subscription services, which may include one-week, one-month, or three-month subscriptions ("Premium Services").
                <ul>
                    <li style="padding-bottom: 10px">
                       <span style="color: black; font-weight: 600"> Auto-Renewal: </span> If you purchase an automatically renewing subscription, your subscription will renew at the end of the period unless cancelled beforehand. The renewal will be at Pickzon's then-current price.
                    </li>

                    <li style="padding-bottom: 10px">
                       <span style="color: black; font-weight: 600"> Cancellation:</span> To avoid charges for a new subscription period, you must cancel before the end of the current subscription period. Deleting your account or the app from your device does not cancel your subscription.
                    </li>

                    <li >
                       <span style="color: black; font-weight: 600"> Pricing Changes: </span> If Pickzon changes the pricing of its Premium Services, you will be notified of such changes, and you will have the opportunity to cancel. Failure to cancel will result in being charged the updated price for your subscription.
                    </li>
                </ul>
            `
        },
        {
            title: "7. Subscription Cancellation",
            description: `
            <ul>
                <li style="padding-bottom: 10px">If you purchased a subscription directly from Pickzon, you can cancel or change your payment method via the payment settings under your profile.</li>
                <li style="padding-bottom: 10px">If you purchased your subscription through a third-party platform (e.g., Apple App Store or Google Play Store), you must manage your subscription through that platform’s account settings.</li>
                <li>After cancellation, you can continue using your Premium Services until the current subscription period ends, but you will not receive any prorated refunds unless specified otherwise. The subscription will not renew after the current period expires.</li>
            </ul>
            `
        },
        {
            title: "8. Requesting Refunds",
            description: `
                If you believe you are entitled to a refund, you may contact us at help@pickzon.com.
                <ul>
                    <li style="padding-bottom: 10px">For subscriptions purchased through third-party stores (Apple App Store, Google Play Store), refund requests should be directed to those platforms, following their policies and procedures.</li>
                    <li> Refunds are not guaranteed and are subject to our sole discretion. </li>
                </ul>
            ` },
        {
            title: "9. User Conduct",
            description: `
            When using Pickzon, you agree to:
            <ul>
                <li style="padding-bottom: 10px"> Treat other users with respect and avoid harassment, abuse, or discrimination. </li>
                <li style="padding-bottom: 10px"> Not engage in unlawful, fraudulent, or harmful behaviour. </li>
                <li style="padding-bottom: 10px"> Refrain from impersonating others or providing false information. </li>
                <li> Avoid using the App for any commercial purposes without our written consent. </li>                
            </ul>

        ` },
        { title: "10. Safety and Verification", description: "While Pickzon ensures mandatory selfie verification to promote user safety, we cannot guarantee the authenticity or conduct of users. We do not conduct background checks on users. It is your responsibility to take necessary precautions during interactions. Pickzon is not liable for any personal or emotional harm caused by interactions on the platform." },
        {
            title: "11. Content and Intellectual Property",
            description: `
            <ul>
                <li style="padding-bottom: 10px"> You are responsible for the content you upload or share on Pickzon. All content must comply with these Terms and applicable laws. </li>
                <li style="padding-bottom: 10px"> By uploading content, you grant Pickzon a non-exclusive, royalty-free, worldwide license to use, modify, and distribute that content as part of the App's services. </li>
                <li> Pickzon retains all intellectual property rights associated with the App, including but not limited to trademarks, logos, and software. </li>
            </ul>
            `
        },
        {
            title: "12. Limitation of Liability",
            description: `
                To the maximum extent permitted by law:
                <ul>
                    <li style="padding-bottom: 10px">Pickzon, its affiliates, and its licensors will not be liable for any direct, indirect, incidental, special, or consequential damages, including but not limited to loss of data, loss of profit, or emotional distress arising from your use of the App.</li>
                    <li> This limitation applies even if we were advised of the possibility of such damages. Your sole and exclusive remedy for dissatisfaction with the App is to stop using it. </li>
                </ul>
                This limitation of liability reflects a fair allocation of risk and is essential for the provision of the App. These limitations will survive even if a remedy is found to have failed in its essential purpose.
            ` },
        { title: "13. Privacy", description: `We respect your privacy. Please review our <a href="Privacy-Policy"> Privacy Policy </a> to understand how we collect, use, and protect your information in accordance with Indian data protection laws.` },
        {
            title: "14. Account Deletion",
            description: `
            You may delete your account at any time through the settings in the Pickzon app. Once your account is deleted:
                <ul>
                    <li style="padding-bottom: 10px">All your data, including profile information, messages, and interactions, will be permanently deleted and cannot be recovered.</li>
                    <li>Deleted accounts cannot be restored. </li>
                </ul>
                If you choose to delete your account, Pickzon will initiate the deletion process immediately, and your data will be permanently removed from our systems, subject to legal or regulatory requirements.
            ` },
        { title: "15. Changes to Terms", description: "Pickzon reserves the right to modify these Terms at any time. Any changes will be posted within the App and will take effect upon posting. Continued use of the App after changes are made constitutes acceptance of the revised Terms." },
        { title: "16. Governing Law", description: "These Terms shall be governed and construed in accordance with the laws of India, and any disputes will be subject to the exclusive jurisdiction of courts located in Delhi, India." },
        { title: "17. Contact Us", description: "For any questions or concerns about these Terms or our services, please reach out to us at <a href='mailto:help@pickzon.com' target='_blank'> help@pickzon.com </a> " },
    ]
    return (
        <Container maxWidth="" disableGutters={true} >
            <Box style={{ padding: '20px' }}>
                <Typography align="center" sx={{ fontSize: { xs: 30, md: 45 }, fontWeight: 800, fontFamily: 'Poppins' }} >
                    Pickzon Terms and Conditions
                </Typography>
                <Typography variant="subtitle1" sx={{ paddingLeft: { xs: 1, md: 25 }, paddingRight: { xs: 1, md: 25 }, paddingBottom: 2, fontFamily: 'Poppins' }} >
                    Welcome to Pickzon! By using our services, you agree to comply with
                    the following terms and conditions. These Terms and Conditions are
                    governed by the laws of India. Please read them carefully. If you do
                    not agree with any part of these terms, you must not use our app.
                </Typography>

                <Box sx={{ paddingLeft: { xs: 1, md: 15 }, paddingRight: { xs: 1, md: 15 }, paddingBottom: 3 }} >
                    {termData.map((item, index) => (
                        <Fragment key={index}>
                            <Typography variant="h6" sx={{ fontWeight: 600, fontFamily: 'Poppins', paddingTop: 2, paddingBottom: 1 }}>{item.title}</Typography>
                            <Typography sx={{ fontFamily: 'Poppins', color: '#666666' }}
                                dangerouslySetInnerHTML={{ __html: item.description }}
                            />
                        </Fragment>
                    ))}
                </Box>
            </Box>
        </Container>
    )
}

export default Term